<template>
  <div>
    <div class="gray title">{{title}}</div>
  </div>
</template>

<script>
import config from '../config'
export default {
  components: {
  },
  data() {
    return {
      title: config.AppTitle
    }
  },
  mounted() {
    console.log('进入cashier页面')
  }

}
</script>

<style scoped  lang="scss">
$bg: #cccccc;
.gray{
  color: $bg;
}
.title{
  margin-top: 50%;
}
</style>
